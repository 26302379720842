<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="800px"
    >
      <v-card class="px-4 ma-0">
        <v-container>
          <v-row align-content="center">
            <v-col
              align-self="center"
              class="py-0"
            >
              <v-card-title class="mt-4">
                ZMIANA ORGANIZACJI
              </v-card-title>
            </v-col>
            <v-col
              align-self="center"
              class="py-0"
            >
              <ConfigurationSearch
                v-model="searchText"
                height="48px"
                width="370px"
                @@search="searchOrg"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-divider />
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="organization in filtredOrg"
                      id="selorg"
                      :key="organization.id"
                      tabindex="0"
                      :class="organization===selectedOrganization ? 'clicked' : ''"
                      @click="selectOrg(organization)"
                    >
                      <td>{{ organization.name }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-divider />
          </v-row>
          <v-row justify="end">
            <v-col
              offset="4"
              cols="4"
              class="ma-0 py-4"
            >
              <v-btn
                dense
                outlined
                persistent-placeholder
                class="mx-1 px-6 large"
                color="primary lighten-1"
                @click="close()"
              >
                Zamknij
              </v-btn>
            </v-col>
            <v-col
              cols="4"
              class="ma-0 py-4"
            >
              <v-btn
                :disabled="!selectedOrganization"
                color="primary lighten-1"
                class="mx-1 px-6 large"
                @click="next()"
              >
                Dalej
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ConfigurationSearch from '@/views/ConfigurationSearch';

export default {
  components: {
    ConfigurationSearch,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    isOpen: Boolean,
    organizations: { type: Array, default: null },
  },
  data: () => ({
    searchText: null,
    filtredOrg: null,
    selectedOrganization: null,
  }),
  computed: {
  },
  beforeMount() {
    this.selectedOrganization = this.value;
    this.filtredOrg = this.organizations;
  },
  methods: {
    next() {
      this.$emit('@next', { organization: this.selectedOrganization });
    },
    close() {
      this.$emit('close');
    },
    searchOrg({ searchText }) {
      this.filtredOrg = this.organizations.filter(
        (x) => x.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 || !searchText,
      );
    },
    selectOrg(organization) {
      this.selectedOrganization = organization;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";
.v-btn {
  background-color: $white !important;
  border: 2px solid $primary-lighten1;
  color:$primary-lighten1;
  width: 192px !important;
  height: 48px !important;
  min-width: 192px !important;
}
.clicked{

    background-color:  $primary-lighten4 !important;
}
 tbody {
  tr:hover {
    background-color:  $primary-lighten4 !important;
  }
}
</style>
